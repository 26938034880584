<template>
  <span class="upload-input">
    <label style="width: 40px" class="file-upload">
      <input type="file" :accept="accept" ref="file" />
      <el-button @click="triggr" :loading="process != 0" type="text" class="el-icon-plus">添加附件</el-button>
    </label>
  </span>
</template>

<script>
import axios from 'axios';
// import config from "/src/config/config";
export default {
  name: 'Upload',
  props: {
    path: String,
    accept: {
      type: String,
      default: 'image/jpeg,image/png'
    }
  },
  data () {
    return {
      process: 0
    };
  },
  methods: {
    triggr () {
      this.$refs.file.click();
    }
  },
  mounted () {
    setTimeout(() => {
      this.$refs.file.addEventListener('change', async event => {
        const originfile = event.target.files[0];
        if (originfile.size > 2097152) {
          this.$message.error('错了哦，超过文件最大限制-2M');
        }
        event.target.value = null;
        const from = new FormData();
        from.append('file', originfile);
        axios({
          url: `${window.location.origin}${this.path}`,
          method: 'post',
          data: from,
          headers: {
            Authorization: window.localStorage.getItem('accessToken')
          }
        })
          .then(res => {
            const path = res.data.data;
            this.$emit('change', path);
            console.log(path);
            this.process = 0;
          })
          .catch(err => {
            this.process = 0;
            console.log(err);
          });
      });
    }, 100);
  }
};
</script>

<style lang="scss" scoped>
.file-upload > input {
  display: none;
}
</style>
